import { api } from './apiSlice';

const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
	isAuthenticated: false,
	jwt: null,
	username: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		logOut: (state) => {
			state.isAuthenticated = false;
			state.jwt = null;
		},
	},
	extraReducers: (builder) => {
		builder.addMatcher(
			api.endpoints.login.matchFulfilled,
			(state, { payload }) => {
				state.username = payload.user.username;
				state.jwt = payload.jwt;
				state.isAuthenticated = true;
			}
		);
	},
});

export const { logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth;
