import { Box, Button, makeStyles } from '@material-ui/core';
import {
	useGetActorsQuery,
	useGetAuthorsQuery,
	useGetCategoriesQuery,
} from '../store/apiSlice';
import React from 'react';
import { TextField } from '@material-ui/core';

import { useForm, Controller } from 'react-hook-form';

import Virtualize from './Virtualize';

export const useStyles = makeStyles((theme) => ({
	flex: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
		gap: theme.spacing(1),
	},
}));

export function BookForm({ setSettings }) {
	const classes = useStyles();
	const { data: authors = [] } = useGetAuthorsQuery();
	const { data: actors = [] } = useGetActorsQuery();
	const { data: categories = [] } = useGetCategoriesQuery();

	const onSubmit = (data) => {
		const newSettings = { page: 1 };

		for (const key in data) {
			if (data[key]) {
				newSettings[key] = data[key];
			}
		}

		setSettings(newSettings);
	};

	const { handleSubmit, control, reset, setValue } = useForm({
		defaultValues: {
			title: '',
			year: '',
			code: '',
			duration: '',
			author_id: '',
			category_id: '',
			actor_id: '',
		},
	});

	return (
		<Box>
			<form className={classes.flex} onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name='title'
					control={control}
					render={({ field }) => (
						<TextField label='Заглавие' id='title' {...field} />
					)}
				/>

				<Virtualize
					label='Автор'
					id='author_id'
					options={[{ name: '------------' }, ...authors]}
					handleChange={(e, options) => setValue('author_id', options?.id)}
				/>

				<Virtualize
					label='Категория'
					id='category_id'
					options={[{ name: '-------------' }, ...categories]}
					handleChange={(e, options) => setValue('category_id', options?.id)}
				/>

				<Controller
					name='year'
					control={control}
					render={({ field }) => (
						<TextField label='Година' id='year' {...field} />
					)}
				/>

				<Virtualize
					label='Актьор'
					id='actor_id'
					options={[{ name: '------------' }, ...actors]}
					handleChange={(e, options) => setValue('actor_id', options?.id)}
				/>

				<Controller
					name='code'
					control={control}
					render={({ field }) => (
						<TextField label='Идентификатор' id='code' {...field} />
					)}
				/>
				<Controller
					name='duration'
					control={control}
					render={({ field }) => (
						<TextField label='Продължителност' id='duration' {...field} />
					)}
				/>
				<Button type='submit' color='primary' variant='outlined'>
					Търсене
				</Button>
			</form>
		</Box>
	);
}
