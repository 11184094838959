import React, { useRef, useState } from 'react';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  makeStyles,
  Paper,
  Typography,
  Link
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import {
  useGetBookDetailsQuery,
  useGetCanAddBooksQuery,
  useGetFileLinkQuery,
  usePostAddBookMutation,
  useGetBookArchiveLinkQuery,
} from '../store/apiSlice';
import { NavLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../store/authSlice';
import messages from '../defs/messages';
import { Helmet } from 'react-helmet-async';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '5px',

    columnGap: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
  },
  bookFile: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
}));

const loader = (value, isFetching) =>
  isFetching ? (
    <Skeleton component="span" width={'25vw'} />
  ) : (
    <span style={{ fontWeight: '400' }}>{value || 'Няма'}</span>
  );

export const BookDetails = () => {
  const classes = useStyles();

  const { id } = useParams();
  const { data: bookDetails, isFetching } = useGetBookDetailsQuery(id);
  const bookArchiveRes = useGetBookArchiveLinkQuery(id);
  const { isAuthenticated } = useSelector(selectCurrentUser);
  const { data } = useGetCanAddBooksQuery(
    { bookId: id },
    { skip: !isAuthenticated }
  );

  const [addBook] = usePostAddBookMutation();

  const handleAddBook = () => {
    addBook({ bookId: id });
  };

  // downloadFile

  const [fileId, setFileId] = useState(null);
  const [shouldDownload, setShouldDownload] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const [shouldCheck, setShouldCheck] = useState(false);

  const audioEl = useRef(null);

  const { data: fileLinkData, isFetching: isFetchingFile } =
    useGetFileLinkQuery({ fileId }, { skip: !fileId });

  if (fileId && !isFetchingFile && shouldDownload && shouldCheck) {
    if (fileLinkData?.ok) {
      const url = fileLinkData.OneDriveRes['@microsoft.graph.downloadUrl'];
      console.log(url);
      window.location.href = url;
    } else {
      alert('Книгата не е налична');
    }
    setShouldCheck(false);
  }

  if (
    fileId &&
    !isFetchingFile &&
    !shouldDownload &&
    !fileLinkData?.ok &&
    shouldCheck
  ) {
    alert('Книгата не е налична');
    setShouldCheck(false);
  }

  const handleDownload =
    ({ id }) =>
    () => {
      setFileId(id);
      setShouldDownload(true);
      setShouldCheck(true);
    };

  const handlePlay =
    ({ id }) =>
    () => {
      if (id === fileId && fileLinkData?.ok) {
        if (isPlaying) audioEl.current.pause();
        else audioEl.current.play();
        setIsPlaying((currentState) => !currentState);
      } else {
        setFileId(id);
        setIsPlaying(true);
      }
      setShouldDownload(false);
      setShouldCheck(true);
    };

  return (
    <>
      <CssBaseline />
      <Helmet>
        <title>{bookDetails?.book.title}</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <Paper className={classes.paper}>
          <NavLink to="/catalogue">Обратно към каталога</NavLink>
          <Typography
            role="alert"
            className={classes.title}
            variant="h4"
            component="h1"
          >
            Детайли
          </Typography>
          {/*make the table vertical so it fits on small devices*/}
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="Детайли">
              <TableBody>
                <TableRow>
                  <TableCell scope="row">Заглавие</TableCell>
                  <TableCell scope="row">
                    {loader(bookDetails?.book?.title, isFetching)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Автор</TableCell>
                  <TableCell scope="row">
                    {loader(bookDetails?.book?.author?.name, isFetching)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Категория</TableCell>
                  <TableCell scope="row">
                    {loader(bookDetails?.book?.category?.name, isFetching)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Година</TableCell>
                  <TableCell scope="row">
                    {loader(bookDetails?.book?.year, isFetching)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Актьор</TableCell>
                  <TableCell scope="row">
                    {loader(bookDetails?.book?.actor?.name, isFetching)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Продължителност</TableCell>
                  <TableCell scope="row">
                    {loader(bookDetails?.book?.duration, isFetching)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell scope="row">Идентификатор</TableCell>
                  <TableCell scope="row">
                    {loader(bookDetails?.book?.code, isFetching)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <br />
          <Typography>Архив:</Typography>
          {bookArchiveRes.isSuccess && bookArchiveRes.data.ok ? <Link href={bookArchiveRes.data.bookURL}>Изтегли архив</Link> : <Typography>Архив за книгата не е наличен.</Typography>}
          <br />
          <Typography>Файлове:</Typography>

          <div className={classes.flex}>
            {bookDetails?.files.map((file, index) =>
              isAuthenticated && data?.isOwn ? (
                <Box
                  borderBottom={2}
                  className={classes.bookFile}
                  key={file.id}
                >
                  <Typography>{loader(file.name, isFetching)}</Typography>
                  <Button
                    aria-label={`${messages.DOWNLOAD_BUTTON} ${file.name}`}
                    onClick={handleDownload({ id: file.id })}
                  >
                    {messages.DOWNLOAD_BUTTON}
                  </Button>

                  <Button
                    aria-label={`${messages.PLAY_PAUSE_BUTTON} ${file.name}`}
                    onClick={handlePlay({ id: file.id })}
                  >
                    {messages.PLAY_PAUSE_BUTTON}
                  </Button>
                </Box>
              ) : (
                <Typography key={index}>
                  {loader(file?.name, isFetching)}
                </Typography>
              )
            )}
          </div>

          {isAuthenticated && data?.isOwn && fileLinkData?.ok && (
            <audio
              ref={audioEl}
              autoPlay={!shouldDownload}
              src={fileLinkData?.OneDriveRes['@microsoft.graph.downloadUrl']}
              muted={isFetchingFile}
              controls
            ></audio>
          )}

          {isAuthenticated && data?.ok && !data?.isOwn && (
            <Button onClick={handleAddBook} variant="outlined">
              Добави в моите книги
            </Button>
          )}
        </Paper>
      </Container>
    </>
  );
};
