module.exports = {
	ERROR_PASSWORD_EMPTY: 'Полето за парола не трябва да е празно.',
	ERROR_REPEAT_PASSWORD_EMPTY:
		'Полето за повторната парола не трябва да е празно.',
	ERROR_PASSWORD_MISMATCH: 'Двете пароли трябва да съвпадат.',
	ERROR_MIN_PASSWORD: 'Паролата трябва да е минимум осем символа.',
	ERROR_PASSWORD_CHARS: 'Паролата трябва да съдържа число и специален символ.',
	ERROR_EMAIL_EMPTY: 'Полето за имейл не трябва да е празно.',
	ERROR_EMAIL_INVALID: 'Моля въведете коректен имейл.',

	ERROR_FNAME_EMPTY: 'Моля въведете първото Ви име.',
	ERROR_LNAME_EMPTY: 'Моля въведете фамилното Ви име.',
	ERROR_EGN_EMPTY: 'Моля въведете Вашето ЕГН.',
	ERROR_EGN_INVALID: 'Моля въведете коректен ЕГН.',

	ERROR_ADDRESS_EMPTY: 'Моля въведете Вашия адрес.',

	ERROR_ORGANIZATION_EMPTY: 'Моля изберете организация или "Не членувам".',
	ERROR_PERCENT_EMPTY: 'Моля въведете процент загубено зрение.',

	APPBAR_BUTTON_CATALOGUE: 'Каталог',
	APPBAR_BUTTON_NEW_TITLES: 'Нови заглавия',
	APPBAR_BUTTON_CONTACT: 'Контакт',
	APPBAR_BUTTON_HELP: 'Помощ',
	APPBAR_BUTTON_REGISTRATION: 'Регистрация',
	APPBAR_BUTTON_LOGIN: 'Вход',
	APPBAR_BUTTON_LOGOUT: 'Изход',
	APPBAR_BUTTON_MY_BOOKS: 'Моите книги',
	APPBAR_BUTTON_PROFILE: 'Профил',

	APPBAR_BUTTON_SYSTEM: 'Система',

	APPBAR_LOGO: 'Лого ССБ',
	APPBAR_SITE_TITLE: 'Заглавие на сайта',

	COLOR_ALTS: {
		BLACK_ON_WHITE: 'черно на бял фон едно от шест',
		WHITE_ON_BLACK: 'бяло на черен фон две от шест',
		YELLOW_ON_BLACK: 'жълто на черен фон три от шест',
		BLACK_ON_YELLOW: 'черно на бял фон четири от шест',
		WHITE_ON_BLUE: 'бяло на син фон пет от шест',
		BLUE_ON_WHITE: 'синьо на бял фон шест от шест'
	},

	DOWNLOAD_BUTTON: 'Изтегли',
	PLAY_PAUSE_BUTTON: 'Пусни/спри',

	LP_HEADER: 'Аудио библиотека',
	LP_WELCOME: ' Добре дошли в нашия сайт',
	LP_TERMS_MESSAGE:
		'Моля, запознайте се с правилата за ползване на аудио библиотеката.',
	LOGINFORM: {
		FIELD_REQUIRED: 'Задължително поле',
		HEADER_MESSAGE: 'Вход в системата',
	},
	REG: {
		HEADER_MESSAGE: 'Регистрация',
		FIELD_REQUIRED: 'Задължително поле',
		FIELD_INVALID: 'Невалидни данни',
		PASSWORDS_MISMATCH: 'Паролите не са еднакви',
		INVALID_NUMBER: 'Невалидно число!',
		USER_SAVE_FAILED:
			'Грешка при запазването на потребителя, моля опитайте отново.',
		USER_REGISTRATION_FAILED:
			'Грешка при регистрирането на потребителя, моля опитайте отново.',
		IMAGE_UPLOAD_USER_NOT_FOUND: 'Грешни параметри.',
		IMAGE_UPLOAD_SAVE_FAILED:
			'Грешка при изпращането на документа, моля опитайте отново.',
	},
	REGDONE: {
		HEADER_MESSAGE: 'Регистрация',
		REGISTRATION_SUCCESS: 'Регистрацията Ви бе приета успешно',
		REGISTRATION_SUCCESS_SUB_MESSAGE:
			'Моля изчакайте одобрението на администратора - ще получите уведомление на имейла Ви.',
		REGISTRATION_FAILURE: 'Регистрацията Ви бе неуспешна',
		REGISTRATION_FAILURE_SUB_MESSAGE:
			'Възникнаха проблеми с регистрацията Ви, и тя не бе приета успешно. Моля опитайте отново по-късно.',
	},

	AUTH_LOGIN_ERROR_MESSAGE:
		'Грешка при влизането в системата, моля проверете потребителя и паролата Ви',
	AUTH_LOGOUT_SUCCESS_MESSAGE: 'Вие излязохте от системата успешно.',
};
