import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

// import { setAuthenticated } from '../store/actions/auth';
import Messages from '../defs/messages';
import LoginForm from '../components/loginForm';
import { selectCurrentUser } from '../store/authSlice';
import { handlePageChange } from '../utils/ally';
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    //   display: 'flex',
    //   overflow: 'auto',
    //   flexDirection: 'column',
    //   align: "center"
  },
  fixedHeight: {
    height: 240,
  },
  fixedHeightPaper: {
    height: 240,
  },
  buttonStyle: {
    margin: theme.spacing(1),
  },
}));

function LandingPage() {
  const classes = useStyles();
  const { isAuthenticated } = useSelector(selectCurrentUser);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Начало</title>
      </Helmet>
      <CssBaseline />
      <Container maxWidth="lg" className={classes.container}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography
                role="alert"
                component="h1"
                variant="h4"
                color="inherit"
                className={classes.title}
              >
                {Messages.LP_HEADER}
              </Typography>
              <hr />
              <Typography
                component="h2"
                variant="h6"
                color="inherit"
                className={classes.title}
              >
                {Messages.LP_WELCOME}
              </Typography>
              <Typography
                component="p"
                color="inherit"
                className={classes.title}
                aria-label={Messages.APPBAR_SITE_TITLE}
              >
                Важно: сайта е обновен, стария интерфейс е на адрес <a href="https://old.webaudio.stackport.com">https://old.webaudio.stackport.com</a>.<br />
                Моля, запознайте се с{' '}
                {
                  <NavLink color="inherit" to="/terms">
                    правилата за ползване
                  </NavLink>
                }{' '}
                на аудио библиотеката.
              </Typography>
              <div className={classes.appBarSpacer} />
              <div align="center">{!isAuthenticated && <LoginForm />}</div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default LandingPage;
