import { default as React } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Messages from '../defs/messages';

import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  fixedHeight: {
    height: 240,
  },
  fixedHeightPaper: {
    height: 240,
  },
  buttonStyle: {
    margin: theme.spacing(1),
  },
  title: {
    align: 'flex-start',
  },
  input: {
    display: 'none',
  },
}));

function RegistrationDone(props) {
  const classes = useStyles(),
    msg = Messages.REGDONE;

  const { state } = useLocation();

  return (
    <div align="center" className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>Успешна Регистрация</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <Paper className={classes.paper}>
          <Typography
            component="h1"
            variant="h4"
            color="inherit"
            noWrap
            className={classes.title}
            aria-label={Messages.APPBAR_SITE_TITLE}
          >
            {msg.HEADER_MESSAGE}
          </Typography>
          <hr />
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
            aria-label={Messages.APPBAR_SITE_TITLE}
            role="alert"
          >
            {state?.error ? msg.REGISTRATION_FAILURE : msg.REGISTRATION_SUCCESS}
          </Typography>
          <Typography
            component="span"
            color="inherit"
            className={classes.title}
            aria-label={Messages.APPBAR_SITE_TITLE}
          >
            {state?.error
              ? msg.REGISTRATION_FAILURE_SUB_MESSAGE
              : msg.REGISTRATION_SUCCESS_SUB_MESSAGE}
          </Typography>
        </Paper>
      </Container>
    </div>
  );
}

export default RegistrationDone;
