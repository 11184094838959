import React from 'react';
import { Box, Typography } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import IconButton from '@material-ui/core/IconButton';

export const PageNav = ({ isFecthing, page, maxPage, handlePageMove }) => {
	return (
		<Box>
			<IconButton
				aria-label='предишна страница'
				onClick={handlePageMove(-1)}
				disabled={page <= 1 || isFecthing}>
				<KeyboardArrowLeft color='primary' />
			</IconButton>
			<Typography component='span' color='primary'>
				Страница {page} от {maxPage}.
			</Typography>
			<IconButton
				aria-label='следваща страница'
				onClick={handlePageMove(1)}
				disabled={isFecthing || page >= maxPage}>
				<KeyboardArrowRight color='primary' />
			</IconButton>
		</Box>
	);
};
