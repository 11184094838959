import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Messages from '../defs/messages';
import { logOut } from '../store/authSlice';
import { Helmet } from 'react-helmet-async';

const styles = (theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
});

const SignOut = () => {
  const dispatch = useDispatch();
  const useStyle = makeStyles(styles);
  const classes = useStyle();

  useEffect(() => {
    dispatch(logOut());
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>Изход</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography
                component="h1"
                variant="h4"
                color="inherit"
                className={classes.title}
                aria-label={Messages.APPBAR_SITE_TITLE}
                role="alert"
              >
                {Messages.AUTH_LOGOUT_SUCCESS_MESSAGE}
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SignOut;

/* const mapStateToProps = (state) => ({
	isAuthenticated: state.auth ? state.auth.isAuthenticated : false,
});

const mapDispatchToProps = (dispatch) => ({
	setJWT: (jwt) => dispatch(setJWT(jwt)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(SignOut));
 */
