import React, { useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

import { Helmet } from 'react-helmet-async';

import Messages from '../defs/messages';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    flexGrow: 1,
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  fixedHeight: {
    height: 240,
  },
  fixedHeightPaper: {
    height: 240,
  },
  buttonStyle: {
    margin: theme.spacing(1),
  },
}));

function LandingPage() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Помощ</title>
      </Helmet>

      <CssBaseline />
      <Container maxWidth="lg" className={classes.container}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography
                role="alert"
                component="h1"
                variant="h4"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {Messages.APPBAR_BUTTON_HELP}
              </Typography>
              <hr />
              <Typography
                component="h2"
                variant="h5"
                color="inherit"
                noWrap
                className={classes.title}
              >
                КАК ДА СИ ИЗТЕГЛИМ КНИГА
              </Typography>
              <Typography
                component="p"
                color="primary"
                className={classes.title}
              >
                След успешната си регистрация, Вие можете да изберете желаното
                от Вас заглавия и да го свалите на компютъра си като се
                ръководите от следните съображения:
              </Typography>
              <Box ml={2}>
                <Typography component="h3" variant="h6">
                  А. ЧРЕЗ ИЗПОЛЗВАНЕТО НА КАТАЛОГ
                </Typography>
                <Typography>
                  От каталога може да изберете желаното заглавие като активирате
                  съответния линк. Отваря се екран с детайлна информация за
                  избраната книга. На този екран имате бутон за обратно връщане
                  в каталога, ако не желаете избраната книга или да я подготвите
                  за изтегляне чрез натисканена бутона „Добави в моите
                  книги“.Отваря се нов прозорец, в който има линк за сваляне на
                  архивен файл с избраното от вас заглавие. Активирайте го и
                  книгата е ваша! Може да се върнете обратно в каталога за нов
                  избор и не забрамяйте! В десет дневен период може за свалите
                  до пет заглавия. ВАЖНО! Ако по време на сесията се случи
                  прекъсване и свалянето завърши неуспешно – опитайте отново
                  след десет минути.
                </Typography>
                <Typography component="h3" variant="h6">
                  Б. ЧРЕЗ ИЗПОЛЗВАНЕ НА ТЪРСАЧКА
                </Typography>
                <Typography>
                  Друг начин за избор на желано от вас заглавие е чрез
                  използването на намиращата се в прозореца на каталога машина
                  за търсене. С нейна помощ може да направите избор по
                  най-различни признаци – по заглавие, по автор, по раздел и
                  т.н. Достатъчно е да въведете един или няколко начални знака,
                  за да получите желаната информация и да следвате описаните
                  по-горе процедури.
                </Typography>
                <Typography component="h3" variant="h6">
                  В. ЛЕНТА С МЕНЮТА
                </Typography>
                <Typography component={'ol'}>
                  <Typography component="li">
                    Каталог – съдържа пълната информация за наличните заглавия в
                    базата. Заглавията с индекс, започващ с буквата N са
                    новозаписани, тези с V са възстановени от златния фонд, а
                    тези с начална буква S обозначават говорещите списания.
                  </Typography>
                  <Typography component="li">
                    В рубриката „Нови заглавия“ може да видите заглавията,
                    записани през последния месец и да изберете харесваните от
                    Вас, като се съобразите с ограничителните условия.
                  </Typography>
                  <Typography component="li">
                    В “Моите книги“ може да направите справка за прочетените от
                    Вас заглавия.
                  </Typography>
                  <Typography component="li">
                    В „Контакт“ имате възможност да изпратите имейл с Вашето
                    мнение или проблеми, които сте срещнали при използването на
                    он-лайн фонотеката.
                  </Typography>
                  <Typography component="li">
                    В „Профил“ може да направите промени в данните на вашия
                    профил.
                  </Typography>
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default LandingPage;
