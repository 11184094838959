import 'typeface-roboto';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import RequireAuth from './components/privateRoute';

import Copyright from './components/Copyright';
import NavBar from './components/NavBar';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import './App.css';

import LandingPage from './pages/landingPage';
import Terms from './pages/terms';
import SignOut from './pages/signOut';
import SignIn from './pages/signIn';
import Registration from './pages/register';
import Catalogue from './pages/catalogue';
import RegistrationDone from './pages/registerDone';
import About from './pages/about';

import { HelmetProvider } from 'react-helmet-async';
// import { setAuthenticated } from './store/actions/auth';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { selectCurrentUser } from './store/authSlice';

import { createTheme, ThemeProvider } from '@material-ui/core';
import ThemePicker from './components/ThemePicker';
import Help from './pages/help';
import { BookDetails } from './pages/bookDetails';
import NewTitles from './pages/newTitles';
import MyBooks from './pages/mybooks';
import Contact from './pages/contactPage';
import Profile from './pages/profilePage';
import { CreatedBy } from './components/CreatedBy';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  menuButton: {
    marginRight: 36,
  },
  content: {
    width: '100%',
    alignItems: 'center',
    direction: 'row',
  },
  container: {
    maxWidth: 'lg',
    width: '100%',
  },
}));

function App() {
  const classes = useStyles();

  const { isAuthenticated } = useSelector(selectCurrentUser);
  const preferences = useSelector((state) => state.preference);

  const theme = useMemo(() => {
    return createTheme({
      palette: {
        primary: {
          main: preferences.color,
        },
        secondary: {
          main: preferences.backgroundColor,
        },
        background: {
          paper: preferences.backgroundColor,
          default: preferences.contrastColor,
        },
        text: {
          primary: preferences.color,
          secondary: preferences.color,
        },
      },
      typography: {
        fontWeightLight: 400,
        fontWeightRegular: 600,
        fontWeightMedium: 700,
        fontWeightBold: 800,
        fontSize: preferences.fontSize,
      },
    });
  }, [preferences]);

  return (
    <Router>
      <HelmetProvider>
        <ThemeProvider theme={theme}>
          <div className={classes.root}>
            <NavBar isAuthenticated={isAuthenticated} />
            <CssBaseline />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Container maxWidth="lg" className={classes.container}>
                <ThemePicker />
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route
                    path="/profile"
                    element={
                      <RequireAuth>
                        <Profile />
                      </RequireAuth>
                    }
                  />
                  <Route path="/sign-in" element={<SignIn />} />
                  <Route path="/terms" element={<Terms />} />
                  <Route path="/sign-out" element={<SignOut />} />
                  <Route path="/sign-up" element={<Registration />} />
                  <Route path="/catalogue" element={<Catalogue />} />
                  <Route
                    path="/mybooks"
                    element={
                      <RequireAuth>
                        <MyBooks />
                      </RequireAuth>
                    }
                  />
                  <Route path="/newtitles" element={<NewTitles />} />
                  <Route path="/catalogue/:id" element={<BookDetails />} />
                  <Route path="/sign-up-done" element={<RegistrationDone />} />
                  <Route path="/help" element={<Help />} />
                </Routes>
                <Box pt={4}>
                  <Copyright />
                  <CreatedBy />
                </Box>
              </Container>
            </main>
          </div>
        </ThemeProvider>
      </HelmetProvider>
    </Router>
  );
}

export default App;
