import { default as React, Fragment, useEffect, useRef, useState } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { useNavigate, useLocation, NavLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

import Messages from '../defs/messages';
import { useLoginMutation } from '../store/apiSlice';

const ErrorPopUp = ({ err }) => {
  const errRef = useRef();

  useEffect(() => {
    if (err) {
      errRef.current.tabIndex = 0;
      errRef.current.focus();
    }
  }, [err]);

  return (
    err && (
      <Grid item xs={12}>
        <div align="center">
          <Typography ref={errRef} color="primary" align="center">
            {err}
          </Typography>
        </div>
      </Grid>
    )
  );
};

function LoginForm(props) {
  const msg = Messages.LOGINFORM;

  const location = useLocation();
  const navigate = useNavigate();
  const [login] = useLoginMutation();
  const [loginErr, setLoginErr] = useState(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: { username: '', password: '' },
  });

  const onSubmit = async (data) => {
    try {
      const user = await login(data).unwrap();
      navigate(from, { replace: true });
    } catch (err) {
      setLoginErr('Грешно потребителско име или парола!');
    }
  };

  const requiredRule = {
    required: { value: true, message: msg.FIELD_REQUIRED },
  };

  const from = location.state?.from?.pathname || '/';
  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Grid item xs={12}>
            <Typography
              ref={props.headingRef}
              component="h1"
              variant="h6"
              color="inherit"
              aria-label={Messages.APPBAR_SITE_TITLE}
              role="alert"
            >
              {msg.HEADER_MESSAGE}
            </Typography>
          </Grid>
          <ErrorPopUp err={loginErr} />
          <Grid item xs={12}>
            <Controller
              name="username"
              rules={requiredRule}
              control={control}
              render={({ field }) => (
                <TextField
                  label="Потребител"
                  error={!!errors.username}
                  helperText={errors?.username?.message}
                  id="username"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="password"
              rules={requiredRule}
              control={control}
              render={({ field }) => (
                <TextField
                  label="Парола"
                  type="password"
                  error={!!errors.password}
                  helperText={errors?.password?.message}
                  id="password"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              key="LogIn"
              variant="outlined"
              color="primary"
              type="submit"
              disabled={props.isSubmitting}
            >
              Вход
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Button
              key="Register"
              variant="outlined"
              color="primary"
              component={NavLink}
              to="/sign-up"
            >
              Регистрация
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
}

export default LoginForm;
