import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import { NavLink } from 'react-router-dom';
import MoreIcon from '@material-ui/icons/MoreVert';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

import Messages from '../../defs/messages';
import { createToolbarLinks } from './utils/createToolbarLinks';

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  grow: {
    flexGrow: 1,
  },
  titleSpacer: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  homeLink: {
    marginLeft: theme.spacing(1),
  },
  logo: {
    maxWidth: 32,
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
}));

export default function DesktopMenu({ isAuthenticated, handleMobileMenuOpen }) {
  const classes = useStyles();

  return (
    <AppBar color="secondary" position="absolute" className={classes.grow}>
      <Toolbar
        aria-label="Начало"
        component={'nav'}
        className={classes.toolbar}
      >
        <Button
          key="ButtonHome"
          tabIndex={0}
          startIcon={
            <img
              src="../images/SSB_Logo.png"
              alt={Messages.APPBAR_LOGO}
              className={classes.logo}
            />
          }
          component={NavLink}
          to="/"
          className={classes.homeLink}
        >
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
            aria-label={Messages.APPBAR_SITE_TITLE}
          >
            WebAudio
          </Typography>
        </Button>
        <div className={classes.titleSpacer} />
        <div className={classes.sectionDesktop}>
          {createToolbarLinks(isAuthenticated)}
        </div>
        <div className={classes.sectionMobile}>
          <IconButton
            aria-label="покажи още"
            aria-controls="primary-menu-mobile"
            aria-haspopup="true"
            color="primary"
            onClick={handleMobileMenuOpen}
          >
            <MoreIcon />
          </IconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
}
