import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { useGetBooksQuery } from '../store/apiSlice';
import React, { useRef, useState } from 'react';

import { PageNav } from '../components/pageNav';
import { Helmet } from 'react-helmet-async';

// mui table imports
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { Skeleton } from '@material-ui/lab/';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  grid: {
    borderColor: theme.palette.secondary,
    borderBottom: '1px solid',
    marginTop: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
}));

function NewTitles() {
  const classes = useStyles();
  const [settings, setSettings] = useState({ page: 1, created_at: 1 });

  const headingRef = useRef(null);

  const { data: bookData, isFetching } = useGetBooksQuery(settings);

  const handlePageMove = (sign) => () =>
    setSettings((prevSettings) => ({
      ...prevSettings,
      page: prevSettings.page + 1 * sign,
    }));

  let books = bookData?.books || Array.from({ length: 20 });

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>Нови заглавия</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <Paper className={classes.paper}>
          <Typography
            ref={headingRef}
            className={classes.title}
            component="h1"
            variant="h4"
            role="alert"
          >
            Нови заглавия
          </Typography>
          <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="Нови заглавия">
              <TableHead>
                <TableRow>
                  <TableCell>Заглавие</TableCell>
                  <TableCell>Автор</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {books.map((book, index) => (
                  <TableRow key={index}>
                    <TableCell scope="row">
                      {!isFetching ? (
                        <Link to={`../catalogue/${book.id}`}>{book.title}</Link>
                      ) : (
                        <Skeleton width={'25vw'} />
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {!isFetching ? (
                        book.author?.name
                      ) : (
                        <Skeleton width={'25vw'} />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {books.length ? (
            <PageNav
              isFetching={isFetching}
              page={settings.page}
              maxPage={bookData?.pageCount}
              handlePageMove={handlePageMove}
            />
          ) : (
            <Typography>Няма намерени книги.</Typography>
          )}
        </Paper>
      </Container>
    </div>
  );
}

export default NewTitles;
