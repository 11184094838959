import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';

import Messages from '../defs/messages';
import { handlePageChange } from '../utils/ally';
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    align: 'center',
  },
  fixedHeight: {
    height: 240,
  },
  fixedHeightPaper: {
    height: 240,
  },
  buttonStyle: {
    margin: theme.spacing(1),
  },
}));

function Terms(props) {
  const classes = useStyles();
  const titleRef = useRef(null);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>Правила</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <Paper className={classes.paper}>
          <Typography
            ref={titleRef}
            component="h1"
            variant="h4"
            color="inherit"
            noWrap
            className={classes.title}
            align="center"
            role="alert"
          >
            ПРАВИЛА
          </Typography>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
            align="center"
          >
            За използване на аудио библиотеката На Съюза на слепите в България
          </Typography>
          <Divider />
          <div className={classes.appBarSpacer} />
          За да използвате базата от аудио книги на Съюза на слепите в България
          /ССБ/: е необходимо да се съобразите със следните изисквания,
          съгласувани с Министерството на културата – дирекция „Авторски права и
          сродни права“:
          <ul>
            <li>
              Да сте със зрителни увреждания, удостоверени с решение на ТЕЛК за
              загуба на зрение над 71% (седемдесет и един процента) /втора
              група/ инвалидност по зрение.
            </li>
            <li>
              Да се регистрирате в нашия сайт като попълните всички данни /ССБ е
              съхранител на лични данни, съгласно изискванията на Закона за
              защита на личните данни/.
            </li>
            <li>
              След получаването на одобрение от администратора на системата
              имате възможност да изтегляте до 5 (пет) заглавия за 10 (десет)
              дневен период.
            </li>
            <li>
              Нямате право да презаписвате и разпространявате свалените заглавия
              и носите наказателна отговорност, в съответствие с разпоредбите на
              Закона за защита на авторските и сродните на тях права.
            </li>
          </ul>
        </Paper>
      </Container>
    </div>
  );
}

export default Terms;
