import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Const from '../defs/const';

const MYHOST = Const.MYHOST.replace('localhost', window.location.hostname);

export const api = createApi({
	baseQuery: fetchBaseQuery({
		baseUrl: `${MYHOST}/api`,
		prepareHeaders: (headers, { getState }) => {
			const token = getState().auth.jwt;
			if (token) headers.set('Authorization', `Bearer ${token}`);
			headers.set('Accept', 'application/json');
			return headers;
		},
	}),
	tagTypes: ['Books'],
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (credentials) => ({
				url: `auth/${Const.URL_API_SIGN_IN}`,
				method: 'POST',
				body: credentials,
			}),
		}),
		signup: builder.mutation({
			query: (credentials) => ({
				url: `auth/${Const.URL_API_SIGN_UP}`,
				method: 'POST',
				body: credentials,
			}),
		}),
		addImg: builder.mutation({
			query: (credentials) => ({
				url: `auth/${Const.URL_API_ADD_IMAGE}`,
				method: 'POST',
				body: credentials,
			}),
		}),

		getAuthors: builder.query({
			query: () => ({
				url: '/author',
				method: 'GET',
			}),
		}),

		getActors: builder.query({
			query: () => ({
				url: '/actor',
				method: 'GET',
			}),
		}),

		getCategories: builder.query({
			query: () => ({
				url: '/category',
				method: 'GET',
			}),
		}),

		getBookArchiveLink: builder.query({
		query: (id) => ({
			url: `${Const.URL_BOOK_GET}/downloadBook/${id}`,
			method: 'GET',
		}),
		}),

		getBooks: builder.query({
			query: (arg) => ({
				url: Const.URL_BOOK_GET,
				method: 'GET',
				params: arg,
			}),
		}),

		getBookDetails: builder.query({
			query: (id) => ({
				url: `${Const.URL_BOOK_GET}/${id}`,
				method: 'GET',
			}),
		}),

		getCanAddBooks: builder.query({
			query: (params) => ({
				url: `${Const.URL_BOOK_GET}/canAdd`,
				method: 'GET',
				params,
			}),
			providesTags: [{ type: 'Books', id: 'LIST' }],
		}),

		postAddBook: builder.mutation({
			query: (credentials) => ({
				url: `${Const.URL_BOOK_GET}/add`,
				method: 'POST',
				body: credentials,
			}),
			invalidatesTags: [{ type: 'Books', id: 'LIST' }],
		}),

		getMyBooks: builder.query({
			query: (arg) => ({
				url: `${Const.URL_BOOK_GET}/mybooks`,
				method: 'GET',
				params: arg,
			}),
			providesTags: [{ type: 'Books', id: 'LIST' }],
		}),

		getMe: builder.query({
			query: () => ({
				url: `auth/me`,
				method: 'GET',
			}),
		}),

		postChangePassword: builder.mutation({
			query: (credentials) => ({
				url: 'auth/change-password',
				method: 'POST',
				body: credentials,
			}),
		}),
		getFileLink: builder.query({
			query: (params) => ({
				url: `${Const.URL_BOOK_GET}/fileLink`,
				method: 'GET',
				params,
			}),
			keepUnusedDataFor: 60 * 60,
		}),
		postSendEmail: builder.mutation({
			query: (credentials) => ({
				url: 'user/contact',
				method: 'POST',
				body: credentials,
			}),
		}),
	}),
});

export const {
  	useGetBookArchiveLinkQuery,
	useLoginMutation,
	useSignupMutation,
	useAddImgMutation,
	useGetBooksQuery,
	useGetAuthorsQuery,
	useGetActorsQuery,
	useGetCategoriesQuery,
	usePrefetch,
	useGetBookDetailsQuery,
	useGetCanAddBooksQuery,
	usePostAddBookMutation,
	useGetMyBooksQuery,
	useGetMeQuery,
	usePostChangePasswordMutation,
	useGetFileLinkQuery,
	usePostSendEmailMutation,
} = api;
