import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import { Box, Typography, Button } from '@material-ui/core';
import React, { useEffect, useRef } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import Messages from '../defs/messages';

import {
  useGetMeQuery,
  usePostChangePasswordMutation,
} from '../store/apiSlice';
import { ORGANIZATIONS } from '../defs/const';
import { handlePageChange } from '../utils/ally';
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  title: {
    paddingBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  grid: {
    borderColor: theme.palette.secondary,
    borderBottom: '1px solid',
    marginTop: theme.spacing(1),
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
  },
}));

function InfoDisplay({ data }) {
  return (
    <>
      <Typography aria-label="Потребител">
        Потребител: {data?.user.username || 'Няма'}
      </Typography>
      <Typography aria-label="Първо име">
        Първо име: {data?.user.first_name || 'Няма'}
      </Typography>
      <Typography aria-label="Последно име">
        Последно име: {data?.user.last_name || 'Няма'}
      </Typography>
      <Typography aria-label="Имейл">
        Имейл: {data?.user.email || 'Няма'}
      </Typography>
      <Typography aria-label="ЕГН">
        ЕГН: {data?.profile?.code || 'Няма'}
      </Typography>
      <Typography aria-label="Адрес">
        Адрес: {data?.profile?.id_card || 'Няма'}
      </Typography>
      <Typography aria-label="Организация">
        Организация: {ORGANIZATIONS[data?.profile?.organization_id] || 'Няма'}
      </Typography>
      <Typography aria-label="Процент загубено зрение">
        Процент загубено зрение: {data?.profile?.lost_vision || 'Няма'}
      </Typography>
    </>
  );
}

function Contact() {
  const classes = useStyles();
  const msg = Messages.LOGINFORM;

  const { data } = useGetMeQuery();
  const [changePassword, value] = usePostChangePasswordMutation();

  const headingRef = useRef(null);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: { password: '', new_password: '' },
  });

  const onSubmit = async (values) => {
    changePassword(values);
    reset();
  };

  const requiredRule = {
    required: { value: true, message: msg.FIELD_REQUIRED },
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>Редактиране на потребител</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <Paper className={classes.paper}>
          <Typography
            ref={headingRef}
            className={classes.title}
            component="h1"
            variant="h4"
            role="alert"
          >
            Редактиране на потребител
          </Typography>

          <InfoDisplay data={data} />

          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className={classes.flex}>
              <Controller
                name="password"
                rules={requiredRule}
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Парола"
                    type="password"
                    error={!!errors.password}
                    helperText={errors?.password?.message}
                    id="password"
                    {...field}
                  />
                )}
              />

              <Controller
                name="new_password"
                rules={requiredRule}
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Нова парола "
                    type="password"
                    error={!!errors.new_password}
                    helperText={errors?.new_password?.message}
                    id="new_password"
                    {...field}
                  />
                )}
              />

              <Button type="submit" color="primary" variant="outlined">
                Изпрати
              </Button>

              {value.isError && (
                <Typography>{value.error.data.error}</Typography>
              )}

              {value.isSuccess && (
                <Typography>Паролата Ви бе променена успешно!</Typography>
              )}
            </Box>
          </form>
        </Paper>
      </Container>
    </div>
  );
}

export default Contact;
