import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import { selectCurrentUser } from '../store/authSlice';

const RequireAuth = ({ children }) => {
	const { isAuthenticated } = useSelector(selectCurrentUser);
	const location = useLocation();
	return isAuthenticated ? (
		children
	) : (
		<Navigate to='/sign-in' state={{ from: location }} />
	);
};

export default RequireAuth;
