import React from 'react';

import Menu from '@material-ui/core/Menu';
import { makeStyles } from '@material-ui/core';

import createItems from './utils/createMobileItems';

const useStyles = makeStyles(() => ({
	buttonGrow: {
		flexGrow: 1,
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
	},
}));

const MobileMenu = ({
	isAuthenticated,
	handleMobileMenuClose,
	isMobileMenuOpen,
	mobileMoreAnchorEl,
}) => {
	const classes = useStyles();
	return (
		<Menu
			anchorEl={mobileMoreAnchorEl}
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			id={'primary-menu-mobile'}
			transformOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={isMobileMenuOpen}
			onClose={handleMobileMenuClose}>
			{createItems(isAuthenticated, classes, true, handleMobileMenuClose)}
		</Menu>
	);
};

export default MobileMenu;
