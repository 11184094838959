module.exports = {
	STATUS_PENDING: 'pending',
	STATUS_ENABLED: 'enabled',
	STATUS_REJECTED: 'rejected',
	STATUS_DISABLED: 'disabled',
	STATUS_ANY: '*',

	STATUSES: ['pending', 'enabled', 'rejected', 'disabled', '*'],

	VERIFY_TOKEN_LENGTH: 32,
	RESET_TOKEN_LENGTH: 32,

	JWT_REGEX: /^Bearer [A-Za-z0-9-=]+.[A-Za-z0-9-=]+.?[A-Za-z0-9-_=]*$/,
	PASSWORD_REGEX: /^(?=.*[a-z])(?=.*\d|.*[!@#$%^&*])(?=.*[A-Z])(?:.{8,})$/,

	MIN_USERNAME_LENGTH: 4,
	JWT_EXPIRY: '168h',

	URL_API_SIGN_IN: 'sign-in',
	URL_API_SIGN_UP: 'sign-up',
	URL_API_ADD_IMAGE: 'sign-up-add-image',

	URL_BOOK_GET: 'book',

	URL_API_ADMIN_GET_NOTIFICATIONS: 'notifications',
	URL_API_ADMIN_GET_USERS: 'users',
	URL_API_ADMIN_GET_STATUSES: 'statuses',
	URL_API_ADMIN_DELETE_USER: 'user',

	FALSE_TELK_URL: 'invalid',

	// MYHOST: 'https://new.webaudio.stackport.com',
	MYHOST: `${window.location.protocol}//${window.location.host}`,

	ADMIN_USERNAME: 'admin',

	PAGINATED_DEFAULT_COUNT: 10,

	ORGANIZATIONS: [
		'---------', // 0, Empty
		'РСО СОФИЯ', // 1
		'РСО ВАРНА', // 2
		'РСО ПЛОВДИВ',
		'РСО МОНТАНА',
		'РСО ПЛЕВЕН',
		'РСО СЛИВЕН',
		'РСО СТАРА ЗАГОРА',
		'РСО БЛАГОЕВГРАД',
		'РСО БУРГАС',
		'РСО РУСЕ',
		'РСО ШУМЕН',
		'РСО ГАБРОВО',
		'РСО СИЛИСТРА',
		'РСО СМОЛЯН',
		'РСО КЪРДЖАЛИ',
		'Не членувам',
	],
};
