import React from 'react';

import { MenuItem } from '@material-ui/core';

import { createToolbarLinks } from './createToolbarLinks';

function renderMobileMenuItems(links) {
	return links.map((link) => (
		<MenuItem key={link.key + 'MenuItem'}>{link}</MenuItem>
	));
}

export default function createItems(
	isAuthenticated,
	classes,
	isInMenu,
	handleMobileMenuClose
) {
	return renderMobileMenuItems(
		createToolbarLinks(
			isAuthenticated,
			classes,
			isInMenu,
			handleMobileMenuClose
		)
	);
}
