import React from 'react';

import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';

const NavBar = ({ isAuthenticated }) => {
	const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
	const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

	const handleMobileMenuClose = () => {
		setMobileMoreAnchorEl(null);
	};

	const handleMobileMenuOpen = (event) => {
		setMobileMoreAnchorEl(event.currentTarget);
	};

	return (
		<>
			<DesktopMenu
				isAuthenticated={isAuthenticated}
				handleMobileMenuOpen={handleMobileMenuOpen}></DesktopMenu>
			<MobileMenu
				isAuthenticated={isAuthenticated}
				handleMobileMenuClose={handleMobileMenuClose}
				isMobileMenuOpen={isMobileMenuOpen}
				mobileMoreAnchorEl={mobileMoreAnchorEl}></MobileMenu>
		</>
	);
};

export default NavBar;
