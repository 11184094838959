import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import LoginForm from '../components/loginForm';
import { Navigate } from 'react-router-dom';

import { selectCurrentUser } from '../store/authSlice';
import { handlePageChange } from '../utils/ally';
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
}));

function SignIn() {
  const classes = useStyles();

  const { isAuthenticated } = useSelector(selectCurrentUser);

  if (isAuthenticated) return <Navigate to="/" />;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>Вход в системата</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <Grid
          container
          spacing={3}
          justifyContent="center"
          alignItems="center"
          direction="row"
        >
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <div align="center">
                <LoginForm />
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default SignIn;
