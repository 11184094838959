import React from 'react';
import { Button } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import BulletedListIcon from '@material-ui/icons/FormatListBulleted';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import HelpIcon from '@material-ui/icons/Help';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LogoutIcon from '@material-ui/icons/ExitToApp';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import Messages from '../../../defs/messages';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

export function createToolbarLinks(
  isAuthenticated,
  classes,
  isInMenu = false,
  handleMobileMenuClose
) {
  const links = [
    <Button
      key="ButtonCatalogue"
      startIcon={<BulletedListIcon />}
      component={NavLink}
      to="/catalogue"
      onClick={handleMobileMenuClose}
      className={isInMenu ? classes.buttonGrow : ''}
    >
      {Messages.APPBAR_BUTTON_CATALOGUE}
    </Button>,
    <Button
      key="ButtonNewTitles"
      startIcon={<NewReleasesIcon />}
      component={NavLink}
      to="/newtitles"
      onClick={handleMobileMenuClose}
      className={isInMenu ? classes.buttonGrow : ''}
    >
      {Messages.APPBAR_BUTTON_NEW_TITLES}
    </Button>,
    <Button
      key="ButtonContact"
      startIcon={<QuestionAnswerIcon />}
      component={NavLink}
      to="/contact"
      onClick={handleMobileMenuClose}
      className={isInMenu ? classes.buttonGrow : ''}
    >
      {Messages.APPBAR_BUTTON_CONTACT}
    </Button>,
    <Button
      key="ButtonHelp"
      startIcon={<HelpIcon />}
      component={NavLink}
      to="/help"
      onClick={handleMobileMenuClose}
      className={isInMenu ? classes.buttonGrow : ''}
    >
      {Messages.APPBAR_BUTTON_HELP}
    </Button>,
  ];

  if (isAuthenticated) {
    links.splice(
      2,
      0,
      <Button
        key="ButtonMyBooks"
        startIcon={<ImportContactsIcon />}
        component={NavLink}
        to="/mybooks"
        onClick={handleMobileMenuClose}
        className={isInMenu ? classes.buttonGrow : ''}
      >
        {Messages.APPBAR_BUTTON_MY_BOOKS}
      </Button>
    );
    links.push(
      <Button
        key="ButtonLogout"
        startIcon={<LogoutIcon />}
        component={NavLink}
        to="/sign-out"
        onClick={handleMobileMenuClose}
        className={isInMenu ? classes.buttonGrow : ''}
      >
        {Messages.APPBAR_BUTTON_LOGOUT}
      </Button>,
      <Button
        key="ButtonProfile"
        startIcon={<AccountCircleIcon />}
        component={NavLink}
        to="/profile"
        onClick={handleMobileMenuClose}
        className={isInMenu ? classes.buttonGrow : ''}
      >
        {Messages.APPBAR_BUTTON_PROFILE}
      </Button>
    );
  } else {
    links.push(
      <Button
        key="ButtonRegistration"
        startIcon={<PlusOneIcon />}
        component={NavLink}
        to="/sign-up"
        onClick={handleMobileMenuClose}
        className={isInMenu ? classes.buttonGrow : ''}
      >
        {Messages.APPBAR_BUTTON_REGISTRATION}
      </Button>
    );
    links.push(
      <Button
        key="ButtonLogin"
        startIcon={<LockOpenIcon />}
        component={NavLink}
        to="/sign-in"
        onClick={handleMobileMenuClose}
        className={isInMenu ? classes.buttonGrow : ''}
      >
        {Messages.APPBAR_BUTTON_LOGIN}
      </Button>
    );
  }

  return links;
}
