import { default as React } from 'react';

import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { useNavigate } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Messages from '../defs/messages';
import Const from '../defs/const';

import { TextField } from '@material-ui/core';

// rtkq
import { useSignupMutation, useAddImgMutation } from '../store/apiSlice';

import { useForm, Controller } from 'react-hook-form';
import { Helmet } from 'react-helmet-async';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
  },
  fixedHeight: {
    height: 240,
  },
  fixedHeightPaper: {
    height: 240,
  },
  buttonStyle: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },

  title: {
    flexGrow: 1,
  },
}));

function getOrganizations() {
  let orgs = [];

  for (let i = 0; i < Const.ORGANIZATIONS.length; ++i) {
    orgs.push({
      value: i + '',
      label: Const.ORGANIZATIONS[i],
    });
  }

  orgs[0].selected = true;

  return orgs;
}
function Registration() {
  const classes = useStyles();
  const msg = Messages;
  const navigate = useNavigate();
  const [signUp] = useSignupMutation();
  const [addImg] = useAddImgMutation();

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    watch,
    setError,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
      password2: '',
      organization: '',
      telkDecision: {},
      email: '',
      firstName: '',
      familyName: '',
      egn: '',
      address: '',
      percentLostVision: 0,
    },
  });

  // NOTE: The file upload implementation isn't clean.

  const matchPasswordsRule = (value) => {
    if (value !== watch('password')) return msg.REG.PASSWORDS_MISMATCH;
    return true;
  };

  const onSubmit = async (values) => {
    // Check if there is a file manualy and if there isn't, don't make the request
    console.log(values);
    if (!values.telkDecision.name) {
      setError('telkDecision', {
        type: 'required',
      });
      return;
    }

    try {
      const response = await signUp(values).unwrap();
      console.log('res: ', response);
      if (response.userId) {
        let file = values.telkDecision;
        const reader = new FileReader();
        reader.onload = async function (event) {
          console.log(event);
          const info = {
            userId: response.userId,
            data: (event.target.result + '').split(',')[1],
            fileName: file.name,
          };
          try {
            // notify the user of the outcome
            const imgData = await addImg(info).unwrap();
            console.log(
              'Uploading the image returned ' + JSON.stringify(imgData.data)
            );
          } catch (err) {
            console.log(
              `ERROR uploading the image for user ${response.data.userId}: ` +
                JSON.stringify(err)
            );
            if (err.stack) console.log('STACK: ' + JSON.stringify(err.stack));
            navigate('/sign-up-done', {
              state: { error: msg.USER_REGISTRATION_FAILED },
            });
          }
        };
        reader.readAsDataURL(file);
        navigate('/sign-up-done', { state: { error: '' } });
      }
    } catch (err) {
      console.log('ERROR signing UP: ' + JSON.stringify(err));
      if (err.stack) console.log('STACK: ' + JSON.stringify(err.stack));

      navigate('/sign-up-done', {
        state: { error: msg.USER_REGISTRATION_FAILED },
      });
    }
  };

  console.log('render');
  console.log(errors.telkDecision);

  return (
    <div align="center" className={classes.root}>
      <CssBaseline />
      <Helmet>
        <title>Регистрация</title>
      </Helmet>
      <Container maxWidth="lg" className={classes.container}>
        <Paper className={classes.paper}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid
              container
              spacing={1}
              justifyContent="flex-start"
              alignItems="flex-start"
              direction="row"
            >
              <Grid item xs={12}>
                <Typography
                  component="h1"
                  variant="h4"
                  color="inherit"
                  noWrap
                  className={classes.title}
                  aria-label={Messages.REG.HEADER_MESSAGE}
                  role="alert"
                >
                  {Messages.REG.HEADER_MESSAGE}
                </Typography>
                <hr />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="username"
                  control={control}
                  rules={{
                    required: { value: true, message: msg.REG.FIELD_REQUIRED },
                  }}
                  render={({ field }) => (
                    <TextField
                      label="Потребител"
                      error={!!errors.username}
                      helperText={errors?.username?.message}
                      id="user"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: msg.ERROR_PASSWORD_EMPTY,
                    },
                    minLength: {
                      value: 8,
                      message: msg.ERROR_MIN_PASSWORD,
                    },
                    pattern: {
                      value:
                        // /^(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                        // /^(?=.*\p{L})(?=.*\d)(?=.*[@$!%*#?&])[\p{L}\d@$!%*#?&]{8,}$/u,
                        /^(?=.*\d)(?=.*[@$!%*#?&])[\d@$!%*#?&]{8,}$/,
                        message: msg.ERROR_PASSWORD_CHARS,
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      label="Парола"
                      type="password"
                      error={!!errors.password}
                      helperText={errors?.password?.message}
                      id="password"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="password2"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: msg.ERROR_PASSWORD_EMPTY,
                    },
                    validate: matchPasswordsRule,
                  }}
                  render={({ field }) => (
                    <TextField
                      label="Повторете паролата"
                      type="password"
                      error={!!errors.password2}
                      helperText={errors?.password2?.message}
                      id="password2"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: { value: true, message: msg.ERROR_EMAIL_EMPTY },
                    pattern: {
                      value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                      message: msg.ERROR_EMAIL_INVALID,
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      label="Имейл"
                      type="text"
                      error={!!errors.email}
                      helperText={errors?.email?.message}
                      id="email"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{
                    required: { value: true, message: msg.ERROR_FNAME_EMPTY },
                  }}
                  render={({ field }) => (
                    <TextField
                      label="Име"
                      type="text"
                      error={!!errors.firstName}
                      helperText={errors?.firstName?.message}
                      id="firstName"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="familyName"
                  control={control}
                  rules={{
                    required: { value: true, message: msg.ERROR_LNAME_EMPTY },
                  }}
                  render={({ field }) => (
                    <TextField
                      label="Фамилия"
                      type="text"
                      error={!!errors.familyName}
                      helperText={errors?.familyName?.message}
                      id="familyName"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="egn"
                  control={control}
                  rules={{
                    required: { value: true, message: msg.ERROR_EGN_EMPTY },
                    validate: (value) =>
                      (value.trim().length === value.length &&
                        !isNaN(value) &&
                        +value > 0) ||
                      'Моля въведете валидно ЕГН.',
                    onChange: (_e) => {
                      trigger('egn');
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      label="ЕГН"
                      type="text"
                      error={!!errors.egn}
                      helperText={errors?.egn?.message}
                      id="egn"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: { value: true, message: msg.ERROR_ADDRESS_EMPTY },
                  }}
                  render={({ field }) => (
                    <TextField
                      label="Адрес"
                      type="text"
                      error={!!errors.address}
                      helperText={errors?.address?.message}
                      id="address"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="organization"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: msg.ERROR_ORGANIZATION_EMPTY,
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      label="Организация"
                      select
                      variant="standard"
                      margin="normal"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      error={!!errors.organization}
                      helperText={errors?.organization?.message}
                      id="organization"
                      {...field}
                    >
                      {getOrganizations().map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="percentLostVision"
                  control={control}
                  rules={{
                    required: { value: true, message: msg.ERROR_PERCENT_EMPTY },
                    validate: (value) =>
                      (value.trim().length === value.length &&
                        !isNaN(value) &&
                        +value >= 70) ||
                      'Моля въведете валиден процент загубено зрение.',
                    onChange: (_e) => {
                      trigger('percentLostVision');
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      label="Процент загубено зрение"
                      type="text"
                      error={!!errors.percentLostVision}
                      helperText={errors?.percentLostVision?.message}
                      id="percentLostVision"
                      {...field}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <input
                  onChange={(e) => {
                    setValue('telkDecision', e.target.files[0]);
                  }}
                  type="file"
                  name="telkDecision"
                  style={{ display: 'none' }}
                  accept="image/*"
                  id="contained-button-file"
                />

                {watch('telkDecision').name ? (
                  <Chip
                    label={getValues('telkDecision').name}
                    onDelete={() => setValue('telkDecision', {})}
                    color="primary"
                  />
                ) : (
                  <label htmlFor="contained-button-file">
                    <Button variant="outlined" component="span">
                      Качи файл{!!errors.telkDecision?.type ? '*' : ''}
                    </Button>
                  </label>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button
                  key="Register"
                  color="primary"
                  variant="outlined"
                  className={classes.buttonStyle}
                  type="submit"
                >
                  Регистрирай
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </div>
  );
}
export default Registration;
