import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useDispatch } from 'react-redux';
import { setColors, setFont } from '../store/preferencesSlice';
import {
  Typography,
  makeStyles,
  useTheme,
  ListItemText,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import messages from '../defs/messages';

//TODO -> fix aria labels

const colorCombinations = [
  {
    color: '#333',
    backgroundColor: '#fff',
    contrastColor: '#e6e6e6',
    altText: messages.COLOR_ALTS.BLACK_ON_WHITE
  },
  {
    color: '#fff',
    backgroundColor: '#000',
    contrastColor: '#262626',
    altText: messages.COLOR_ALTS.WHITE_ON_BLACK
  },
  {
    color: '#ffd806',
    backgroundColor: '#000',
    contrastColor: '#262626',
    altText: messages.COLOR_ALTS.YELLOW_ON_BLACK
  },
  {
    color: '#000',
    backgroundColor: '#ffd806',
    contrastColor: '#e6c300',
    altText: messages.COLOR_ALTS.BLACK_ON_YELLOW
  },
  {
    color: '#fff',
    backgroundColor: '#074074',
    contrastColor: '#0b69c1',
    altText: messages.COLOR_ALTS.WHITE_ON_BLUE
  },
  {
    color: '#074074',
    backgroundColor: '#fff',
    contrastColor: '#e6e6e6',
    altText: messages.COLOR_ALTS.BLUE_ON_WHITE
  },
];

const fonts = [{ fontSize: 14 }, { fontSize: 15 }, { fontSize: 16 }];

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    gap: '5px',
  },
}));

const ColorPicker = () => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const buttons = colorCombinations.map((combination) => {
    let comb = { ...combination };
    let alt = comb.altText;
    delete comb.altText;
    return (
      <MenuItem
        key={comb.color + comb.backgroundColor}
        style={{ ...comb, padding: '1.5em' }}
        aria-label={alt}
        onClick={(e) => {
          handleClose();
          dispatch(setColors(comb));
        }}
      >
        А б в
      </MenuItem>
    );
  });

  return (
    <>
      <Button
        tabIndex={-1}
        aria-controls="color-menu"
        aria-haspopup="true"
        color="primary"
        onClick={handleClick}
      >
        Промени цвета
      </Button>
      <Menu
        id="color-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {buttons}
      </Menu>
    </>
  );
};

const FontPicker = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.up('md'));
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const fontButtons = fonts.map((font, index) => {
    return (
      <MenuItem
        aria-label={index == 0 ? 'малък' : index == 1 ? 'среден' : 'голям'}
        key={font.fontSize}
        onClick={() => {
          handleClose();
          dispatch(setFont(font));
        }}
        style={{
          fontSize: (index + 2) * 3 + font.fontSize / 2,
          display: 'flex',
          justifyContent: 'center',
          padding: isMatch ? '.5em' : '1.5em',
        }}
      >
        Aa
      </MenuItem>
    );
  });

  return isMatch ? (
    <div className={classes.container}>
      <Typography>Размер на шрифта:</Typography>
      {fontButtons}
    </div>
  ) : (
    <>
      <Button
        tabIndex={-1}
        color="primary"
        aria-controls="font-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Размер на шрифта
      </Button>
      <Menu
        id="font-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {fontButtons}
      </Menu>
    </>
  );
};

export default function ThemePicker() {
  const classes = useStyles();

  return (
    <div style={{ marginTop: '5px' }} className={classes.container}>
      <ColorPicker />
      <FontPicker />
    </div>
  );
}
