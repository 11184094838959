const { createSlice } = require('@reduxjs/toolkit');

const initialState = {
	color: '#333',
	backgroundColor: '#fff',
	contrastColor: '#e6e6e6',
	fontSize: 15,
};

const preferencesSlice = createSlice({
	name: 'preferences',
	initialState,
	reducers: {
		setColors: (state, { payload }) => {
			state.color = payload.color;
			state.backgroundColor = payload.backgroundColor;
			state.contrastColor = payload.contrastColor;
		},
		setFont: (state, { payload }) => {
			state.fontSize = payload.fontSize;
		},
	},
});

export const { setColors, setFont } = preferencesSlice.actions;

export default preferencesSlice.reducer;
