import { configureStore } from '@reduxjs/toolkit';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';

import { api } from './apiSlice';
import authReducer from './authSlice';
import preferenceReducer from './preferencesSlice';

const reducers = combineReducers({
	auth: authReducer,
	preference: preferenceReducer,

	[api.reducerPath]: api.reducer,
});

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	blacklist: [api.reducerPath],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(api.middleware),
});

let persistor = persistStore(store);

export { persistor };
export default store;
