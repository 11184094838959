import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

export function CreatedBy() {
	return (
		<Typography variant='body2' color='textSecondary' align='center'>
			Created by{' '}
			<Link color='inherit' href='https://github.com/theftpdaemon'>
				theftpdaemon
			</Link>
			{' & '}
			<Link color='inherit' href='https://github.com/S460s'>
				S460.
			</Link>
		</Typography>
	);
}
